














import './scss/TheAuth.scss';
import Vue from 'vue';
import { Component } from 'vue-property-decorator';

@Component({
    name: 'BaseAuth',
})
export default class BaseAuth extends Vue {}
